<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        Details updated Successfully!
      </b-alert>
    </div>

    <div class="card" style="margin-top:-30px">
      <h5
        v-if="this.$storageData.login_type == 1"
        style="padding-top: 10px; margin-left: 10px"
      >
        {{ title }}
      </h5>
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="row">
            <div
              v-if="
                this.$storageData.login_type == 2 &&
                  (this.$storageData.profile.empTypeID == 2 ||
                    this.$storageData.profile.empTypeID == 11)
              "
              class="col-md-2"
            >
              <label>Select Country </label>
              <multiselect
                v-model="country"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getRestaurantCities()"
              ></multiselect>
            </div>

            <div
              v-if="
                this.$storageData.login_type == 2 &&
                  (this.$storageData.profile.empTypeID == 2 ||
                    this.$storageData.profile.empTypeID == 11)
              "
              class="col-md-2"
            >
              <label>Select City </label>
              <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="readRestaurantBranchData()"
              ></multiselect>
            </div>
            <!--END Cities List-->
            <div
              v-if="
                this.$storageData.login_type == 2 &&
                  (this.$storageData.profile.empTypeID == 2 ||
                    this.$storageData.profile.empTypeID == 11)
              "
              class="col-md-3 mb-3"
            >
              <label>Select Restaurant Brand</label>
              <multiselect
                v-model="brandData"
                :options="brandArr"
                :show-labels="false"
                label="name"
                track-by="restID"
                @input="readRestaurantBranchData()"
              ></multiselect>
            </div>

            <div
              v-if="
                this.$storageData.login_type == 2 &&
                  (this.$storageData.profile.empTypeID == 2 ||
                    this.$storageData.profile.empTypeID == 11)
              "
              class="col-md-4"
            >
              <label>Select Restaurant Branch </label>
              <multiselect
                v-model="branchID"
                :options="branches"
                label="branchName"
                track-by="restBranchID"
                @input="onchangeBranch()"
              ></multiselect>
            </div>
            <!-- <div class="col-md-4 mb-3" v-if="this.$storageData.login_type==1">
              <label class="form-label" for="formrow-endTime-input">Select Corporate Branch </label>
              <multiselect v-model="corpBranchID" :options="corporates" track-by="corpBranchID" label="corpBranchName" @input="readMealPlanData"
                  ></multiselect>
          </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs
            content-class="pt-0 px-2 text-muted"
            nav-class="p-0"
            nav-wrapper-class="col-sm-3"
            pills
            vertical
          >
            <b-tab active title="Prep Time" title-item-class="mb-2">
              <b-card-text>
                <div class="row">
                  <div class="col-md-8">
                    <h4>1. What is Preparation Time ?</h4>
                    <p class="mt-3">
                      It is the time you take to prepare food, pack the items
                      and mark the order ready for pickup.
                    </p>
                    <div style="display:flex;margin-top: 42px;">
                      <div>
                        <span class="cookicon"
                          ><i class="fas fa-utensils prepIcon"></i
                        ></span>
                        <br />
                        Cooking Time
                      </div>

                      <i class="fas fa-plus prepIcon"></i>
                      <div>
                        <span class="cookicon"
                          ><i class="fas fa-shopping-bag prepIcon"></i
                        ></span>
                        <br />
                        Packing Time
                      </div>
                      <i class="fas fa-plus prepIcon"></i>
                      <div>
                        <span class="cookicon"
                          ><i class="fas fa-location-arrow prepIcon"></i
                        ></span>
                        <br />
                        Click Food Ready
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <h4>2. Why is it important ?</h4>
                    <ul class="mt-3">
                      <li class="mt-4">
                        Promise better Delivery time to customers
                      </li>
                      <li class="mt-4">Ensure fast pickups from restaurants</li>
                      <li class="mt-4">
                        Reduce wait time for our delivery executives at your
                        restaurant
                      </li>
                    </ul>
                  </div>
                </div>
                <br /><br />
                <hr style="height: 2px;color: #b1b1b1;" />
                <div class="row">
                  <div class="col-md-8">
                    <h4>Average Preparation Time</h4>
                    <p class="mt-3">
                      Tell us the time you usually take to prepare order
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label> Preparartion Time </label>
                    <multiselect
                      v-model="delTime"
                      :options="deliveryTimeArr"
                      :show-labels="false"
                    ></multiselect>
                  </div>
                </div>
                <div class=" row" style="float:right;">
                  <b-spinner
                    v-if="loading"
                    class="m-2 col-3"
                    role="status"
                    variant="primary"
                  ></b-spinner>
                  <button
                    :disabled="loading"
                    class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                    type="button"
                    @click.prevent="updatePrepTime()"
                  >
                    Update
                  </button>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Account" title-item-class="mb-2">
              <b-card-text>
                <h5>{{ restDetails.name }}</h5>
                <h6 style="color:#b7b7b7">{{ restDetails.branchName }}</h6>
                <!-- <p> Mumbai </p> -->

                <div
                  v-if="restDetails.isReadyToAcceptOrder == 1"
                  style="display: flex;"
                >
                  <span class="dot"></span>
                  <p>Active</p>
                </div>
                <div v-else style="display: flex;">
                  <span class="dotClosed"></span>
                  <p>Closed</p>
                </div>

                <button
                  v-if="
                    this.$storageData.login_type == 1 ||
                      this.$storageData.login_type == 2
                  "
                  class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light"
                  style="margin-bottom:8px;"
                  @click="showModal(restDetails.restIdentifierString)"
                >
                  View Qr
                </button>

                <h6 style="color:#b7b7b7">{{ restDetails.address }}</h6>
                <div class="table-responsive">
                  <table
                    class="table table-striped mb-0"
                    style="max-width: 500px;"
                  >
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{{ restDetails.name }}</td>
                      </tr>
                      <!-- <tr>
                                            <td>CloudKitch ID</td>
                                            <td>Thornton</td>   
                                        </tr> -->
                      <tr>
                        <td>Onbording Date</td>
                        <td>{{ restDetails.created }}</td>
                      </tr>
                      <tr>
                        <td>Restaurant Type</td>
                        <td>{{ restDetails.restaurantType }}</td>
                      </tr>
                      <tr>
                        <td>Speciality</td>
                        <td>{{ restDetails.speciality }}</td>
                      </tr>
                      <tr>
                        <td>CloudKitchID</td>
                        <td>{{ restDetails.restBranchCloudKitchID }}</td>
                      </tr>
                      <tr>
                        <td>Web Url</td>
                        <td>{{ restDetails.restIdentifierString }}</td>
                      </tr>
                      <tr>
                        <td>Sodexo</td>
                        <td>
                          <div
                            v-if="restDetails.isSodexoApproved == 1"
                            style="display: flex;"
                          >
                            <span class="dot"></span>
                            <p>Active</p>
                          </div>
                          <div v-else style="display: flex;">
                            <span class="dotClosed"></span>
                            <p>In-active</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Finance" title-item-class="mb-2">
              <b-card-text>
                <div class="table-responsive">
                  <h6>
                    Finance
                    <span>
                      <button
                        v-if="this.$storageData.login_type == 1"
                        class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light"
                        style="position: absolute;right: 2%;"
                        @click="financeModal = !financeModal"
                      >
                        Edit <i class="uil-edit-alt"></i>
                      </button>
                    </span>
                  </h6>
                  <table
                    class="table table-striped mb-0"
                    style="margin-top: 24px;"
                  >
                    <tbody>
                      <tr>
                        <td>Bank Name</td>
                        <td>{{ bankName }}</td>
                      </tr>
                      <tr>
                        <td>Account Number</td>
                        <td>{{ accountNO }}</td>
                      </tr>
                      <tr>
                        <td>IfSC Code</td>
                        <td>{{ ifscCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>(* For Queries call +91 7710999666)</p>
              </b-card-text>
            </b-tab>
            <b-tab title="Timings" title-item-class="mb-2">
              <b-card-text>
                <h6>Restaurant timings</h6>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <label class="form-label" for="formrow-starttime-input"
                      >Start Time*</label
                    >
                  </div>
                  <div class="col-md-3">
                    <label class="form-label" for="formrow-starttime-input"
                      >End Time*</label
                    >
                  </div>
                  <div class="col-md-3">
                    <label class="form-label" for="formrow-starttime-input"
                      >Closed*</label
                    >
                  </div>
                </div>
                <div
                  v-for="(data, index) in dayArr"
                  :key="data.id"
                  class="row"
                  style="margin-bottom: 16px;"
                >
                  <div class="col-md-3">
                    <label> {{ data.name }} </label>
                  </div>
                  <div class="col-md-3">
                    <div v-if="posts.closed[index] == 1">
                      <input
                        id="example-time-input-start"
                        v-model="posts.startTime[index]"
                        :disabled="data.disabled"
                        class="form-control"
                        type="time"
                        value="13:45:00"
                      />
                    </div>
                    <div v-else-if="posts.closed[index] == 0">
                      <input
                        id="example-time-input-start"
                        v-model="posts.startTime[index]"
                        :disabled="!data.disabled"
                        class="form-control"
                        type="time"
                        value="13:45:00"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div v-if="posts.closed[index] == 1">
                      <input
                        id="example-time-input-end"
                        v-model="posts.endTime[index]"
                        :disabled="data.disabled"
                        class="form-control"
                        type="time"
                        value="13:45:00"
                      />
                    </div>
                    <div v-else-if="posts.closed[index] == 0">
                      <input
                        id="example-time-input-end"
                        v-model="posts.endTime[index]"
                        :disabled="!data.disabled"
                        class="form-control"
                        type="time"
                        value="13:45:00"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div
                      class="form-check form-switch form-switch-md mb-3"
                      dir="ltr"
                    >
                      <input
                        id="customSwitchsizemd"
                        v-model="posts.closed[index]"
                        class="form-check-input"
                        type="checkbox"
                        @change="isClosed(index)"
                      />
                    </div>
                  </div>
                </div>
                <div class=" row" style="float:right;">
                  <b-spinner
                    v-if="loading"
                    class="m-2 col-3"
                    role="status"
                    variant="primary"
                  ></b-spinner>
                  <button
                    :disabled="loading"
                    class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                    type="button"
                    @click.prevent="updateRestTimings()"
                  >
                    Update
                  </button>
                </div>
              </b-card-text>
            </b-tab>
            <!-- <b-tab title="Charges" title-item-class="mb-2">
                          <b-card-text>
                            <div class="row">
                              <div class="col-md-8">
                                <h4> Retaurant Charges </h4>
                                <p class="mt-3"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book </p>
                              </div>
                              <div class="col-md-4">                                       
                                <label class="form-label" for="formrow-charges-input">Restaurant Charges</label>                                    
                                <div class="input-group"><div class="input-group-text">₹</div>                                                 
                                  <input type="text" class="form-control" id="formrow-charges-input" v-model="posts.restCharges">                                                  
                                </div>                                         
                              </div>
                            </div>
                            <div class=" row" style="float:right;">
                              <b-spinner v-if="loading"
                                class="m-2 col-3"
                                variant="primary"
                                role="status"
                              ></b-spinner>
                              <button type="button" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading" @click.prevent="updateRestaurantBranch(5);">Update</button>
                            </div>
                          </b-card-text>
                        </b-tab> -->
            <!-- <b-tab title="Users" title-item-class="mb-2">
                            <b-card-text>
                              <h6> User List </h6>
                                <div class="table-responsive mb-0">
                                  <table class="table table-striped mb-0" style="max-width: 500px;margin-top:15px;">
                                        <thead>
                                          <tr>
                                          <td>UserName</td>
                                          <td>EmailID</td>
                                          <td>Phone No</td>
                                          <td>Role</td>
                                          
                                          </tr>
                                        </thead>
                                        <tbody v-for="(data, index) in getManagers" :key="data.id">
                                            <tr>
                                                <td>{{data.name}}</td>
                                                <td>{{data.emailID}}</td>
                                                <td>{{data.phoneNO}}</td>
                                                <td>{{data.role}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                        
                                    </div>
                            </b-card-text>
                        </b-tab> -->
            <b-tab
              v-if="
                this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 20 ||
                  this.$storageData.profile.empTypeID == 11 ||
                  this.$storageData.profile.empTypeID == 9
              "
              title="Admin"
              title-item-class="mb-2"
            >
              <b-card-text>
                <div class="row">
                  <div class="col-md-8"><h6>Admin List</h6></div>
                  <div class="col-md-4" v-if="$can('create restaurant employee')">
                    <router-link
                      :to="{
                       path: '/form/restaurantAdmimForm/:type',
                        params: { restBranchID: this.postRestBranchID }
                      }"
                    >
                      <button
                        class="btn btn-themeYellow w-md waves-effect waves-light mt-3"
                      >
                        Add Admin
                      </button>
                    </router-link>
                  </div>
                </div>

                <div class="table-responsive mb-0">
                  <table
                    class="table table-striped mb-0"
                    style="max-width: 500px;margin-top:15px;"
                  >
                    <thead>
                      <tr>
                        <td>Action</td>
                        <td>UserName</td>
                        <td>EmailID</td>
                        <td>Phone No</td>
                        <td>Role</td>
                      </tr>
                    </thead>
                    <tbody v-for="data in getManagers" :key="data.id">
                      <tr>
                        <td>
                          <router-link
                              :to="{
                       name: 'restaurantAdmimForm',
                        params: { restBranchID:postRestBranchID,employee:data.restEmpID,type:'edit'}
                      }"
                              v-if="$can('edit restaurant employee')"
                          >
                            <button type="button" class="btn btn-outline-warning btn-sm">
                              <i
                                  class="uil uil-edit-alt"
                                  title="Edit"
                                  style="font-size: 19px"
                              ></i>

                            </button>
                            </router-link>

                          &nbsp;
                          <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteEpmloyee(data.restEmpID)">
                            <i
                                class="uil uil-trash"
                                title="Delete"
                                style="font-size: 19px"
                            ></i>
                          </button>


                        </td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.emailID }}</td>
                        <td>{{ data.phoneNO }}</td>
                        <td>{{ data.role }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="FSSAI" title-item-class="mb-2">
              <b-card-text>
                <div class="table-responsive">
                  <h6>
                    FSSAI Details
                    <span>
                      <button
                        v-if="this.$storageData.login_type == 1"
                        class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light"
                        style="position: absolute;right: 2%;"
                        @click="fssaiModal = !fssaiModal"
                      >
                        Edit <i class="uil-edit-alt"></i>
                      </button>
                    </span>
                  </h6>
                  <table
                    class="table table-striped mb-0"
                    style="margin-top: 24px;"
                  >
                    <tbody>
                      <tr>
                        <td>License NO</td>
                        <td>{{ licenseNO }}</td>
                      </tr>
                      <tr>
                        <td>Expiry Date</td>
                        <td>{{ licenseExpiryDate }}</td>
                      </tr>
                      <tr>
                        <td>FSSAI Document</td>
                        <td style="cursor:pointer">
                          <a
                            v-if="imageData.length > 0"
                            :href="imageData"
                            download
                            target="_blank"
                            >Download</a
                          >
                          <i
                            v-if="imageData.length > 0"
                            class="uil uil-download-alt"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="GST" title-item-class="mb-2">
              <b-card-text>
                <h5 class="mb-4">Add GST Details</h5>
                <label class="form-label mb-2" for="formrow-gstno"
                  >GSTIN Number *</label
                >
                <input
                  id="formrow-gstno"
                  v-model="gstNOHidden"
                  :class="{
                    'is-invalid': submitted && $v.gstNOHidden.$error
                  }"
                  class="form-control mb-2"
                  style="text-transform: uppercase;"
                  type="password"
                />
                <div
                  v-if="submitted && $v.gstNOHidden.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.gstNOHidden.required"
                    >This value is required.</span
                  >
                </div>

                <label class="form-label mb-2" for="formrow-gstagain"
                  >Please enter GSTIN Number again*</label
                >
                <input
                  id="formrow-gstagain"
                  v-model="gstNO"
                  :class="{
                    'is-invalid': submitted && $v.gstNO.$error
                  }"
                  class="form-control mb-2"
                  style="text-transform: uppercase;"
                  type="text"
                />
                <div
                  v-if="submitted && $v.gstNO.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.gstNO.required">This value is required.</span>
                  <span v-else-if="!$v.gstNO.sameAsPassword"
                    >GST number not matching</span
                  >
                </div>
                <label class="form-label mb-2" for="formrow-gstname"
                  >Business/Legal name as per GST Ceriticate*</label
                >
                <input
                  id="formrow-gstname"
                  v-model="gstName"
                  :class="{
                    'is-invalid': submitted && $v.gstName.$error
                  }"
                  class="form-control mb-2"
                  type="text"
                />
                <div
                  v-if="submitted && $v.gstName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.gstName.required"
                    >This value is required.</span
                  >
                </div>
                <div class=" row" style="float:right;">
                  <b-spinner
                    v-if="loading"
                    class="m-2 col-3"
                    role="status"
                    variant="primary"
                  ></b-spinner>
                  <button
                    :disabled="loading"
                    class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                    type="button"
                    @click.prevent="updateGstDetails()"
                  >
                    Update
                  </button>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Other Settings" title-item-class="mb-2">
              <b-card-text>
                <h6>
                  Other Settings
                  <span>
                    <button
                      v-if="this.$storageData.login_type == 1"
                      class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light"
                      style="position: absolute;right: 2%;"
                      @click="otherSettingModal = !otherSettingModal"
                    >
                      Edit <i class="uil-edit-alt"></i>
                    </button>
                  </span>
                </h6>
                <table
                  class="table table-striped mb-0"
                  style="margin-top: 24px;"
                >
                  <tbody>
                    <tr>
                      <td>Restaurant Type</td>
                      <td>{{ restaurantType }}</td>
                    </tr>
                    <tr>
                      <td>Meta Title</td>
                      <td>{{ metaTitle }}</td>
                    </tr>
                    <tr>
                      <td>Meta Description</td>
                      <td>{{ metaDescription }}</td>
                    </tr>
                    <tr>
                      <td>Meta Keywords</td>
                      <td>{{ metaKeywords }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
            </b-tab>
            <b-tab
              title="Manage Food Menu"
              title-item-class="mb-2"
              @click="manageFoodMenu()"
            >
              <b-card-text>
                <div class="col-sm-12 col-md-4">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show &nbsp;&nbsp;
                      <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                        style="margin-left:5px; margin-right:5px"
                      ></b-form-select
                      >&nbsp;&nbsp;&nbsp; entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div
                  class="col-sm-12 col-md-4 row"
                  style="position: absolute;
    right: 0;
    top: -2px;"
                >
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        class="form-control form-control-sm ms-2"
                        placeholder="Search..."
                        type="search"
                      ></b-form-input>
                    </label>
                  </div>
                </div>

                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :current-page="currentPage"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="tableData"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    fixed-header
                    hover
                    responsive="sm"
                    sticky-header="500px"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(price)="data">
                      <span>₹{{ data.item.price }}</span>
                    </template>
                    <template v-slot:cell(AvailableInBranch)="data">
                      <div
                        class="form-check form-switch form-switch-md mb-3"
                        dir="ltr"
                      >
                        <input
                          v-if="data.item.branchMenuID > 1"
                          id="autoSizingCheck2"
                          :checked="data.item.branchMenuID"
                          class="form-check-input"
                          title="Available"
                          type="checkbox"
                          @change="addMenuToRestBranch(data.item.dishID)"
                        />

                        <input
                          v-else
                          id="autoSizingCheck2"
                          :checked="data.item.branchMenuID"
                          class="form-check-input"
                          title="Mark as available"
                          type="checkbox"
                          @change="addMenuToRestBranch(data.item.dishID)"
                        />
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- modal for finance  -->
    <b-modal
      v-model="financeModal"
      title="Finance"
      @ok="updateRestaurantBranch(3)"
    >
      <div class="col-md-12 mb-2">
        <label class="form-label" for="formrow-bankName-input">Bank Name</label>
        <input
          id="formrow-bankName-input"
          v-model="bankName"
          class="form-control"
          type="text"
        />
      </div>
      <div class="col-md-12 mb-2">
        <label class="form-label" for="formrow-accountNO-input"
          >Account Number</label
        >
        <input
          id="formrow-accountNO-input"
          v-model="accountNO"
          class="form-control"
          type="text"
        />
      </div>

      <div class="col-md-12 mb-2">
        <label class="form-label" for="formrow-ifscCode-input">IFSC Code</label>
        <input
          id="formrow-ifscCode-input"
          v-model="ifscCode"
          class="form-control"
          type="text"
        />
      </div>
    </b-modal>
    <!-- end of finance  -->
    <!-- fssaiModal -->
    <b-modal
      v-model="fssaiModal"
      title="FSSAI Details"
      @ok="updateRestaurantBranch(7)"
    >
      <div class=" col-md-12 mb-2">
        <label class="form-label" for="formrow-licenseNO-input"
          >License document upload</label
        ><br />
        <div class="file-upload-form">
          Choose File
          <input class="uploadInput" type="file" @change="previewImage" />
        </div>
        <div v-if="imageData.length > 0" class="image-preview col-md-3">
          <img v-if="docExt != 'pdf'" :src="imageData" class="preview" />
        </div>
      </div>
      <div class="col-md-12 mb-2">
        <label class="form-label" for="formrow-licenseNO-input"
          >FSSAI License Number</label
        >
        <input
          id="formrow-licenseNO-input"
          v-model="licenseNO"
          :min="nowDate"
          class="form-control"
          type="text"
        />
      </div>

      <div class="col-md-12 mb-2">
        <label>Expiry Date</label>
        <br />
        <input
          id="formrow-licenseNO-input"
          v-model="licenseExpiryDate"
          class="form-control"
          type="date"
        />
        <!-- <date-picker v-model="licenseExpiryDate" :first-day-of-week="1" lang="en"></date-picker> -->
      </div>
    </b-modal>
    <!-- end of fssaiModal -->

    <!-- other settings modal -->
    <b-modal
      v-model="otherSettingModal"
      title="Other Settings"
      @ok="updateRestaurantBranch(9)"
    >
      <div class="col-md-12">
        <label class="form-label" for="formrow-restType-input"
          >Restaurant Type*</label
        >
        <multiselect
          v-model="restType"
          :options="restTypeArr"
          label="typeName"
          track-by="restTypeID"
        ></multiselect>
      </div>
      <!-- <div v-if="restType.restTypeID===2" class="col-md-12">
        <label class="form-label" for="formrow-endTime-input">Select Corporate Branch </label>
        <multiselect v-model="corpBranchID" :options="corporates" track-by="corpBranchID" label="corpBranchName"
        ></multiselect>
    </div> -->

      <div class="col-sm-12">
        <div class="mb-3">
          <label for="metatitle">Meta title</label>
          <input
            id="metatitle"
            v-model="metaTitle"
            class="form-control"
            name="metatitle"
            type="text"
          />
        </div>
      </div>
      <div class="col-sm-12">
        <div class="mb-3">
          <label for="metakeywords">Meta Keywords</label>
          <input
            id="metakeywords"
            v-model="metaKeywords"
            class="form-control"
            name="metakeywords"
            type="text"
          />
        </div>
      </div>

      <div class="mb-3 mb-0">
        <label for="metadescription">Meta Description</label>
        <textarea
          id="metadescription"
          v-model="metaDescription"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </b-modal>
    <!-- end of other settings modal -->

    <!-- modal for finance  -->
    <b-modal id="qrModal" centered hide-footer="true">
      <div style="text-align:center;    margin-bottom: 21px;">
        <img :src="this.showQRImage" />
      </div>
    </b-modal>
    <!-- end of finance  -->
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from "../../../helpers/fakebackend/auth-header";
import helperFile from "../../../helpers/helper";
import { required, sameAs } from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Restaurant Details",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Restaurant Details",
      items: [
        {
          text: "Profile",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],
      //menu datatable ////
      showQRImage: "",
      menuTableData: [],
      nowDate: new Date().toISOString().slice(0, 10),
      tableData: [],
      restDetails: [],
      gstNOHidden: "",
      gstName: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      financeModal: false,
      fssaiModal: false,
      // filters data
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      branches: [],
      branchID: "",
      restBranchID: 0,
      brandArr: [],
      brandData: [],
      //// filter end
      fields: [
        {
          key: "title",

          sortable: true
        },
        {
          key: "price",

          sortable: true
        },
        {
          key: "foodType",

          sortable: true
        },
        {
          key: "AvailableInBranch",

          sortable: true
        },

        {
          key: "categoryName",

          sortable: true
        }
      ],

      dayArr: [
        { name: "Monday", disabled: false },
        { name: "Tuesday", disabled: false },
        { name: "Wednesday", disabled: false },
        { name: "Thursday", disabled: false },
        { name: "Friday", disabled: false },
        { name: "Saturday", disabled: false },
        { name: "Sunday", disabled: false }
      ],
      posts: {
        endTime: [
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00"
        ],
        closed: ["", "", "", "", "", "", ""],
        startTime: [
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00"
        ]
      },
      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      deliveryTimeArr: [
        "5",
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55",
        "60"
      ],
      delTime: "",
      id: 0,
      postRestBranchID: "",
      bankName: "",
      accountNO: "",
      ifscCode: "",
      imageData: "",
      docExt: "",
      // expiryDate: "",
      encodeImage: "",
      licenseNO: "",
      selectImage: "",
      licenseDoc: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      restaurantType: "",
      otherSettingModal: false,
      restTypeArr: [
        { restTypeID: 1, typeName: "Restaurant" },
        { restTypeID: 2, typeName: "Cafeteria" }
      ],
      restType: "",
      licenseExpiryDate: "",
      gstNO: "",
      getManagers: []
    };
  },

  validations: {
    gstName: {
      required
    },
    gstNOHidden: {
      required
    },
    gstNO: {
      required,
      sameAsPassword: sameAs("gstNOHidden")
    }
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      // alert(this.id);
      sessionStorage.setItem("data", this.id); // Save to sessionStorage
    }
  },
  methods: {
    showModal(webUrl) {
      this.showQRImage = "";
      this.axios
        .post(this.$loggedRole+"/generateQrforRestaurant", { stringForQr: webUrl })
        .then(result => {
          this.showQRImage = result.data.data;
          this.$root.$emit("bv::show::modal", "qrModal");
        });
    },
    onchangeBranch() {
      if (this.branchID != "") {
        this.postRestBranchID = this.branchID.restBranchID;
      }
      this.getRestaurantFoodMenu();
      this.getRestaurantProfileInfo();
    },
    isClosed(index) {
      console.log(index);
    },
    manageFoodMenu() {
      this.getRestaurantFoodMenu();
    },
    addMenuToRestBranch(dishID) {
      this.axios
        .post(this.$loggedRole+"/manageRestBranchMenu", {
          dishID: dishID,
          restBranchID: this.postRestBranchID
        })
        .then(response => {
          if (response.data.status == 200) {
            this.getRestaurantFoodMenu();
            alert(response.data.message);
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getRestaurantFoodMenu() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantMenu", { restBranchID: this.postRestBranchID })
        .then(result => {
          this.tableData = result.data.data;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    updatePrepTime() {
      this.loading = true;
      let formdata = new FormData();
      formdata.append("avgPreparationTime", this.delTime);
      formdata.append("restBranchID", this.postRestBranchID);
      this.axios
        .post(this.$loggedRole+"/updateRestaurantBranch", formdata, {
          headers: authHeader()
        })
        .then(result => {
          this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.loading = false;

          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 2000);

          // console.warn(result);
        })
        .catch(function(error) {
          this.loading = false;
          console.error(error.response);
          alert("Something went Wrong");
        });
    },
    updateGstDetails() {
      // alert(this.gstName);

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append("gstNO", this.gstNO);
        formData.append("gstName", this.gstName);
        formData.append("restBranchID", this.postRestBranchID);

        this.axios
          .post(this.$loggedRole+"/updateRestaurantBranch", formData, {
            headers: authHeader()
          })
          .then(result => {
            this.status = result.data.data;
            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);

            // console.warn(result);
          })
          .catch(error => {
            this.loading = false;
            console.error(error.response);
            alert("Something went Wrong");
          });
      }
    },
    // updateRestTimings(){

    //  this.submitted = true;
    //   // stop here if form is invalid
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   } else {
    //     this.loading = true;
    //       let formData = new FormData();

    //         formData.append('startTime',JSON.stringify(this.posts.startTime));
    //         formData.append('endTime',JSON.stringify(this.posts.endTime));
    //         formData.append('closed',JSON.stringify(this.posts.closed));
    //         formData.append('restBranchID', this.postRestBranchID);

    //   this.axios.post(this.$loggedRole+"/updateRestaurantBranch",  formData, {
    //       headers: authHeader()
    //     })
    //   .then((result)=>{
    //          this.status = result.data.data;
    //           this.showDismissibleAlert = true;
    //           this.loading = false;

    //           setTimeout(() => {
    //               this.showDismissibleAlert = false;
    //           },2000);

    //      // console.warn(result);

    //   })
    //  .catch((error) =>{
    //    this.loading = false;
    //                     console.error(error.response);
    //                     alert("Something went Wrong");
    //                 });
    //   }
    // },
    updateRestTimings() {
      this.submitted = true;

      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // } else {
      this.loading = true;
      let formData = new FormData();

      formData.append("startTime", JSON.stringify(this.posts.startTime));
      formData.append("endTime", JSON.stringify(this.posts.endTime));
      formData.append("closed", JSON.stringify(this.posts.closed));
      formData.append("restBranchID", this.postRestBranchID);

      this.axios
        .post(this.$loggedRole+"/updateRestaurantBranch", formData, {
          headers: authHeader()
        })
        .then(result => {
          this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.loading = false;

          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 2000);

          // console.warn(result);
        })
        .catch(error => {
          this.loading = false;
          console.error(error.response);
          alert("Something went Wrong");
        });
      //}
    },
    updateRestaurantBranch(flag) {
      // alert(flag);
      // flag : 3=>finance, 5=> restCharges , 9=> other settings,7=>fssai license
      // alert(flag);
      this.submitted = true;
      // stop here if form is invalid
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // } else {
      this.loading = true;
      let formData = new FormData();

      switch (flag) {
        case 3:
          formData.append("bankName", this.bankName);
          formData.append("accountNO", this.accountNO);
          formData.append("ifscCode", this.ifscCode);
          break;
        case 5:
          formData.append("restCharges", this.posts.restCharges);

          break;
        case 7:
          formData.append("licenseExpiryDate", this.licenseExpiryDate);
          formData.append("document", this.encodeImage);
          formData.append("licenseNO", this.licenseNO);
          break;
        case 9:
          formData.append("restaurantType", this.restType.restTypeID);
          formData.append("metaTitle", this.metaTitle);
          formData.append("metaDescription", this.metaDescription);
          formData.append("metaKeywords", this.metaKeywords);
          break;

        default:
        // code block
      }

      formData.append("restBranchID", this.postRestBranchID);
      this.axios
        .post(this.$loggedRole+"/updateRestaurantBranch", formData, {
          headers: authHeader()
        })
        .then(result => {
          this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.loading = false;

          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 2000);

          switch (flag) {
            case 2:
              this.bankName = result.data.data.bankName;
              this.ifscCode = result.data.data.ifscCode;
              this.accountNO = result.data.data.accountNO;
              break;
            case 7:
              this.licenseNO = result.data.data.licenseNO;
              this.licenseExpiryDate = result.data.data.licenseExpiryDate;
              this.imageData = result.data.data.licenseDoc;

              break;
            case 9:
              this.restaurantType = result.data.data.restaurantType;
              this.metaTitle = result.data.data.metaTitle;
              this.metaDescription = result.data.data.metaDescription;
              this.metaKeywords = result.data.data.metaKeywords;
              break;
            default:
            // code block
          }
        })
        .catch(error => {
          this.loading = false;

          alert(error.response.data.data);
        });
      //}
    },
    getRestaurantProfileInfo() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantProfileInfo", {
          restBranchID: this.postRestBranchID
        })
        .then(result => {
          this.getManagers = result.data.getManagers;
          this.restDetails = result.data.restDetails;
          this.title =
            result.data.restDetails.name +
            " - " +
            result.data.restDetails.branchName;
          this.posts.startTime = result.data.restDetails.startTime;
          this.posts.endTime = result.data.restDetails.endTime;
          this.posts.closed = result.data.restDetails.closed;
          this.gstNOHidden = result.data.restDetails.gstNO;
          this.gstNO = result.data.restDetails.gstNO;
          this.delTime = result.data.restDetails.avgPreparationTime;
          this.gstName = result.data.restDetails.gstName;
          this.posts.restCharges = result.data.restDetails.restCharges;
          this.bankName = this.restDetails.bankName;
          this.ifscCode = this.restDetails.ifscCode;
          this.accountNO = this.restDetails.accountNO;
          this.licenseNO = this.restDetails.licenseNO;
          this.licenseExpiryDate = this.restDetails.licenseExpiryDate;
          this.imageData =
            this.restDetails.licenseDoc != null
              ? this.restDetails.licenseDoc
              : "";
          this.docExt =
            this.imageData.substring(
              this.imageData.lastIndexOf(".") + 1,
              this.imageData.length
            ) || this.imageData;
          this.restaurantType = this.restDetails.restaurantType;
          this.metaTitle = this.restDetails.metaTitle;
          this.metaDescription = this.restDetails.metaDescription;
          this.metaKeywords = this.restDetails.metaKeywords;
          this.restType = this.restDetails.restTypeArr;
          // console.log(this.restDetails);
        });
    },

    previewImage: function(event) {
      // console.log(event);
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        //  console.log(input.files[0]);
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];
        // if(this.encodeImage.type.includes('application') == true)
        // {
        //   this.docExt = 'pdf';
        // }
        this.docExt = /[^/]*$/.exec(this.encodeImage.type)[0];
      }
    },

    getRestaurantBrandsByCompanyID() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: this.$storageData.profile.companyID
        })
        .then(response => {
          this.brandArr = response.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getAllCountry() {
      this.axios.post(this.$loggedRole+"/getAllCountry").then(response => {
        this.countryArr = response.data.data;
        this.country = this.countryArr ? this.countryArr[0] : "";
        this.getAllCities();
      });
    },
    getAllCities() {
      if (this.$storageData.profile.city != "") {
        this.cityArr = [{ city: this.$storageData.profile.city }];
        this.city = this.cityArr ? this.cityArr[0] : "";
        this.getRestaurantBrands();
      } else {
        this.cityArr = [];
        this.city = "";
        this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            this.getRestaurantBrands();
          });
      }
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          city: this.$storageData.profile.city,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(result => {
          this.brandArr = result.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {
          restID: this.$storageData.profile.restID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID:
            this.$storageData.login_type == 1
              ? this.restaurantID.restID
              : this.brandData
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 11) {
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },
    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID:
            this.$storageData.profile.empTypeID == 2 ||
            this.$storageData.profile.empTypeID == 11
              ? 0
              : this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          this.postRestBranchID = this.restBranchID ? this.restBranchID : 0;
          this.getRestaurantProfileInfo();
          this.getRestaurantFoodMenu();
        });
    },
    deleteEpmloyee(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you really want to delete this employee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!"
      }).then((result)=> {
        if (result.isConfirmed) {
          this.axios
              .post(this.$loggedRole+
                  "/delete-restaurant-employee",
                  { empRestID: id },
              )
              .then((result) => {
                this.$swal({
                  text: result.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                }).then(()=>{
                  location.reload();
                });
              })
              .catch(error => {
                this.loading = false;
                this.$swal({
                  text: error.response.data.message,
                  icon: "error",
                });
              });
        }
      });


    }
  },

  mounted() {
    console.log(this.$storageData.profile.restBranchID);
    if (this.$storageData.profile.restBranchID=== undefined || this.$storageData.profile.restBranchID == 0) {
      this.postRestBranchID = sessionStorage.getItem("data");
    } else {
      this.postRestBranchID = this.$storageData.profile.restBranchID;
    }
    // console.log(this.postRestBranchID);
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.selectImage = helperFile.previewImage;

    this.$storageData.login_type == 2 &&
    (this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11)
      ? this.$storageData.login_type == 1
        ? this.getAllCountry()
        : this.getRestaurantCountry()
      : this.getRestaurantProfileInfo();
  },

  middleware: "authentication"
};
</script>
<style>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
.dot {
  height: 15px;
  width: 15px;
  background-color: #a3ce74;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}
.dotClosed {
  height: 15px;
  width: 15px;
  background-color: grey;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}
.cookicon {
  height: 65px;
  width: 65px;
  background-color: #d5d8d2;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}
.prepIcon {
  margin: 22px;
  font-size: 23px;
}

.table-striped td {
  border: 1px solid gainsboro !important;
}
img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}
</style>
